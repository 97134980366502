/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import './src/assets/theme/css/page.css';
import './src/assets/theme/css/custom.css';

// You can delete this file if you're not using it
